/* Header.module.css */

.header {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    color: #000000;
    width: 100%;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border: 2px solid transparent;

    border-bottom-color: #000;
  }
  
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-family: OCR A;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 20px;

  }
  
  .menu {
    display: flex;
    list-style: none;
    margin-right: 80px;
  }
  
  .menu li {
    margin-left: 50px;
  }
  
  .menu a {
    display: inline-block;
    padding: 2px 5px;
    font-family: Comic Sans MS;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 4px 4px 0 #000;
    text-decoration: none;
    border: 2px solid transparent;
    border-radius: 15px;
    border-color: #000000;
    transition: all 0.3s ease-in-out;
  }
  
  .menu a:hover {
    color: #fff;
    background-color: #000000;
    transform: scale(1.1);

  }
  