.container {
    padding: 4rem 2rem;
    background: #fff;
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    position: relative;
  }
  
  .title {
    font-family: 'OCR A', monospace;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 3rem;
    text-transform: uppercase;
    /* border: 3px solid #000; */
    padding: 1rem 2rem;
    /* border-radius: 20px; */
    /* box-shadow: 5px 5px 0 #000; */
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .card {
    background: #fff;
    border: 3px solid #000;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 5px 5px 0 #000;
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(30px);
  }
  
  .card:nth-child(1) { transition-delay: 0.1s; }
  .card:nth-child(2) { transition-delay: 0.3s; }
  .card:nth-child(3) { transition-delay: 0.5s; }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 8px 8px 0 #000;
  }
  
  .icon {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    display: inline-block;
    transition: transform 0.3s ease;
  }
  
  .card:hover .icon {
    transform: rotate(10deg) scale(1.1);
  }
  
  h3 {
    font-family: 'OCR A', monospace;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #000;
  }
  
  p {
    font-family: 'Comic Sans MS';
    line-height: 1.5;
    color: #333;
  }
  
  .ctaWrapper {
    text-align: center;
    margin: 6rem auto 0;
    padding: 0 2rem;
    
  }
  
  .ctaButton {
    text-decoration: none;
    font-family: 'Comic Sans MS';
    font-size: 1.5rem;
    padding: 1.2rem 4rem;
    background: #fff;
    color: #000;
    border: 3px solid #000;
    border-radius: 30px;
    box-shadow: 5px 5px 0 #000;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    display: inline-block;
  }
  .ctaButton:hover {
    background: #000;
    color: #fff;
    transform: translate(5px, 5px);
    box-shadow: 0 0 0 #000;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 1.8rem;
      padding: 0.8rem 1.5rem;
    }
    
    .grid {
      grid-template-columns: 1fr;
    }
    
    .card {
      padding: 1.5rem;
    }
    
    h3 {
      font-size: 1.3rem;
    }
    
    p {
      font-size: 0.9rem;
    }
    .ctaButton {
        font-size: 1.2rem;
        padding: 1rem 2rem;
      }
  }