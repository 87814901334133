.hero {
    width: 100%;
    background: #ffffff;
    padding: 4rem 2rem 8rem;
    min-height: 80vh;
    border-bottom: 3px solid #000;
    position: relative;
  }
  
  .mainHeader {
    text-align: center;
    margin-bottom: 6rem;
  }
  
  .mainTitle {
    display: inline-flex;
    align-items: baseline;
    gap: 1.5rem;
    position: relative;
    padding: 1rem;
  }
  

  .defaultText {
    font-family: 'OCR A', monospace;
    font-size: 1.5rem;

  }


  .staticText {
    margin-right: 100px;
    font-family: 'OCR A', monospace;
    font-size: 2.5rem;
    white-space: nowrap;
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 1rem 2rem;
    border: 3px solid #000;
    border-radius: 20px;
    box-shadow: 5px 5px 0 #000;
  }
  
  
  .rotatingContainer {
    
    position: relative;
    height: 3.5rem;
    width: 500px;
    overflow: hidden;
    border: 3px solid #000;
    border-radius: 20px;
    box-shadow: 5px 5px 0 #000;
    background: #fff;
  }
  
  
  
  
  .rotatingTextWrapper {
    
    position: relative;
    height: 4.5rem;
    width: 400px;
  }
  
  
  .rotatingText {
    position: absolute;
    
    right: 0;
    top: 80%;
    transform: translateY(50%) translateX(100%);
    font-family: 'OCR A', monospace;
    font-size: 2rem;
    width: 100%;
    padding: 0 1.5rem;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    white-space: nowrap;
  }
  
  .rotatingText.active {
    opacity: 1;
    transform: translateY(-50%) translateX(0);
  }
  
  .rotatingText:not(.active) {
    transform: translateY(-50%) translateX(-100%);
    opacity: 0;
  
  }
  
  .subtitle {
    display: inline-block;
    margin-top: 2rem;
    font-size: 2rem;
    border: 3px solid #000;
    padding: 1rem 2rem;
    border-radius: 20px;
    box-shadow: 5px 5px 0 #000;
  }
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: start;
  }
  
  .infoBlock {
    padding-right: 2rem;
  }
  
  .featuresList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .featuresList li {
    font-family: 'OCR A', monospace;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    border: 2px solid #000;
    border-radius: 15px;
    box-shadow: 3px 3px 0 #000;
    background: #fff;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .featuresList li:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease;
  }
  
  .featuresList li:hover {
    transform: translate(5px, 5px);
    box-shadow: 0 0 0 #000;
  }
  
  .featuresList li:hover:before {
    left: 0;
  }
  
  .featuresList li.active {
    background: #000;
    color: #fff;
    transform: translate(5px, 5px);
    box-shadow: 0 0 0 #000;
  }
  .imageBlock {
    position: relative;
    height: 500px;
    border: 3px solid #000;
    border-radius: 20px;
    box-shadow: 5px 5px 0 #000;
    overflow: hidden;
    background: #fff;
  }
  
  .featureImage {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit : contain;
    opacity: 0;
    /* transform: scale(0.9); */
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .imageActive {
    opacity: 1;
    transform: scale(0.9);
    z-index: 1;
  }
  
  
  .ctaWrapper {
    text-align: center;
    margin: 6rem auto 0;
    padding: 0 2rem;
    
  }
  
  .ctaButton {
    text-decoration: none;
    font-family: 'Comic Sans MS';
    font-size: 1.5rem;
    padding: 1.2rem 4rem;
    background: #fff;
    color: #000;
    border: 3px solid #000;
    border-radius: 30px;
    box-shadow: 5px 5px 0 #000;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    display: inline-block;
  }
  .ctaButton:hover {
    background: #000;
    color: #fff;
    transform: translate(5px, 5px);
    box-shadow: 0 0 0 #000;
  }
  @media (max-width: 768px) {
    .content {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  
    .imageBlock {
      height: 300px;
      order: -1;
    }
  
    .mainTitle {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  
    .subtitle {
      font-size: 1.5rem;
    }
  
    .rotatingTextWrapper {
      display: flex;
      justify-content: center;
      width: 80%;
      height: auto;
      min-height: 80px;
    }
    .staticText {
      font-size: 2rem;
      padding: 0.8rem 1.5rem;
    }
    .rotatingContainer {
      width: 100%;
      height: 3rem;
    }
  
    .rotatingText {
      font-size: 1.5rem;
    }
    .featureItem {
      padding: 1rem;
      font-size: 0.9rem;
    }
    .ctaButton {
      font-size: 1.2rem;
      padding: 1rem 2rem;
    }
  }